import React, {useState, useEffect, useContext} from 'react';
import {EmptyJobCheck, registerAction} from "../Helper/functions";
import {useNavigate} from "react-router-dom";
import {Link} from 'react-router-dom';
import {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";

import data from '../assets/fake-data/data-collection'


function SignUp(props) {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [regsiterCredentials, setRegisterCredentials] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setError(null);
        }, 5000);
        return () => clearTimeout(timer);
    }, [error]);

    const handleRegister = async (e) => {
        e.preventDefault();
        const {email, password, confirmPassword} = regsiterCredentials;
        if (password !== confirmPassword) {
            setError('Password and confirm password do not match');
            return;
        }
        if (email === '' || password === '' || confirmPassword === '') {
            setError('Please fill all fields');
            return;
        }
        if (email.length < 6 || password.length < 5) {
            setError('Email or password is too short');
            return;
        }

        await registerAction(`https://api.${window.location.host}/register`, email, password).then(res => {
            EmptyJobCheck(res.data);
        }).then(() => {
            navigate('/signin');
        })
            .catch(c => {
                setError(c?.response?.data?.error?.detail ?? 'Something went wrong')
            });
    }

    return (
        <div>

            <section className="bg-sign-in">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="content-left vertical-carousel">
                                <div className="content-slide">
                                    <div className="content-slide">

                                        <Swiper
                                            className="collection-1"
                                            direction={"vertical"}
                                            spaceBetween={30}
                                            slidesPerView={3}

                                            loop={true}
                                            modules={[Autoplay]}
                                            autoplay={{
                                                delay: 4500,
                                                disableOnInteraction: false,
                                            }}

                                        >

                                            {
                                                data.slice(0, 7).map(idx => (
                                                    <SwiperSlide key={idx.id}>
                                                        <div className="slider-item">
                                                            <div className="tf-product ">
                                                                <div className="image">
                                                                    <img src={idx.img} alt="Dreamin"/>
                                                                </div>
                                                                <h6 className="name"><Link
                                                                    to="/item-details">{idx.title}</Link></h6>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }

                                        </Swiper>
                                        <Swiper
                                            className="collection-1"
                                            direction={"vertical"}
                                            spaceBetween={30}
                                            slidesPerView={3}

                                            loop={true}
                                            modules={[Autoplay]}
                                            autoplay={{
                                                delay: 4000,
                                                disableOnInteraction: false,
                                            }}

                                        >

                                            {
                                                data.slice(3, 9).map(idx => (
                                                    <SwiperSlide key={idx.id}>
                                                        <div className="slider-item">
                                                            <div className="tf-product ">
                                                                <div className="image">
                                                                    <img src={idx.img} alt="Dreamin"/>
                                                                </div>
                                                                <h6 className="name"><Link
                                                                    to="/item-details">{idx.title}</Link></h6>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }

                                        </Swiper>
                                        <Swiper
                                            className="collection-1"
                                            direction={"vertical"}
                                            spaceBetween={30}
                                            slidesPerView={3}


                                            loop={true}
                                            modules={[Autoplay]}
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}

                                        >

                                            {
                                                data.slice(3, 13).map(idx => (
                                                    <SwiperSlide key={idx.id}>
                                                        <div className="slider-item">
                                                            <div className="tf-product ">
                                                                <div className="image">
                                                                    <img src={idx.img} alt="Dreamin"/>
                                                                </div>
                                                                <h6 className="name"><Link
                                                                    to="/item-details">{idx.title}</Link></h6>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }

                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="sign-in-form style2">
                                <h2>SIGN UP</h2>
                                {error === null && <p>Welcome back! Please enter your details</p>}
                                {error !== null && <p style={{color: 'red', fontWeight: 'bold'}}>{error}</p>}
                                <form action="#" id="contactform">
                                    <fieldset><input onChange={(e) => setRegisterCredentials({
                                        ...regsiterCredentials,
                                        email: e.target.value
                                    })} name="email" tabIndex="2" aria-required="true" type="email"
                                                     placeholder="Your email" required=""/></fieldset>
                                    <fieldset><input
                                        onChange={(e) => setRegisterCredentials({
                                            ...regsiterCredentials,
                                            password: e.target.value
                                        })}
                                        id="showpassword" name="password" tabIndex="2" aria-required="true"
                                        type="password" placeholder="Set your password" required=""/>
                                    </fieldset>
                                    <fieldset><input
                                        onChange={(e) => setRegisterCredentials({
                                            ...regsiterCredentials,
                                            confirmPassword: e.target.value
                                        })}
                                        id="showpassword" name="password" tabIndex="2" aria-required="true"
                                                     type="password" placeholder="Confirm the password" required=""/>
                                    </fieldset>
                                    <button className="tf-button submit" type="submit"
                                            onClick={(e) => handleRegister(e)}
                                    >SIGN UP
                                    </button>
                                </form>
                                <div className="choose-sign">
                                    Already have an account? <Link to="/signin">Login</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default SignUp;
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const PopClose = ({ setShown }) => {
    return (
      <div
        className="position-absolute d-flex justify-content-center align-items-center"
        style={{
          position: "absolute",
          display: "flex",
          background: "#2196e7",
          top: 20,
          right: 20,
          height: 25,
          width: 25,
          borderRadius: 5,
        }}
      >
        <FontAwesomeIcon icon={faClose} onClick={() => setShown(false)} />
      </div>
    );
  };
  

export default PopClose
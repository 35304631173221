import React , {useState , useEffect, useContext} from 'react';
import { DataContext } from '../../Helper/context';
import { Languages } from '../../Helper/languages';

import { Link } from 'react-router-dom';
import './styles.scss';

import logo from '../../assets/images/logo/logo.png';


function Footer2(props) {
    const [isVisible, setIsVisible] = useState(false);
    const{ language } = useContext(DataContext);
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    
const {home,terms_and_conditions:terms ,create,inspiration,FAQ_short_title:faq} = Languages[language];
    return (
        <footer className="footer style-2">
                <div className="footer-inner">
                    <div className="tf-container">
                        <div className="row">

                        </div>
                    </div>
                </div>

                <div className="bottom-inner">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bottom">
                                
                                   <div className="content-left">
                                        <img style={{maxWidth:'180px'}} src={logo} alt="Binabox" />
                                        <p className="copy-right">dreamin 2023 - ALL rights reserved</p>
                                   </div>

                                    <ul className="menu-bottom">
                                        <li><Link to="/">{home}</Link></li>
                                        <li><Link to="/terms">{terms}</Link></li>
                                        <li><Link to="/create">{create}</Link></li>
                                        <li><Link to="/inspiration">{inspiration}</Link></li>
                                        <li><Link to="/faq-v1">{faq}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
            </footer>
    );
}

export default Footer2;
import React, {useState, useContext, useEffect} from 'react';
import { Languages } from '../Helper/languages';
import {DataContext} from "../Helper/context";
import PageTitle from '../components/pagetitle/PageTitle';
import axios from "axios";
import {Link} from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import RangeSlider from "../components/RangeSlider/RangeSlider";
import {EmptyJobCheck, removeEmptyJobFromLocalStorage} from "../Helper/functions";
import Footer2 from "../components/footer/Footer2";
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import infoIcon from '../assets/images/icon/info-icon.png'

function Create() {
    const {user, language} = useContext(DataContext);
    let rates = JSON.parse(localStorage.getItem('Rates'))
    const hugeQualityArray = Array.from(Array(101).keys()).map((i) => i / 100)
    const steps = Array.from(Array(150).keys()).map((i) => i + 1)
    const dimensions = [64, 128, 192, 256, 320, 384, 448, 512, 576, 640, 704, 768, 832, 896, 960, 1024, 1088, 1152, 1216, 1280, 1344, 1408, 1472, 1536, 1600, 1664, 1728, 1792, 1856, 1920, 1984, 2048,]
    const CFG = Array.from(Array(61).keys()).map((i) => i / 2)
    const [error, setError] = useState("")
    const [price, setPrice] = useState(25)
    const [checked, setChecked] = useState(false)
    const [quality, setQuality] = useState('draft')
    const [artist, setArtist] = useState("Euler a")
    const [settings, setSettings] = useState({
        seed: -1,
        prompt: '',
        negative_prompt: '',
        width: 512,
        height: 512,
        quality: 0,
        steps: 20,
        cfg: 7,
    })

    const QualitySetter = (template) => {
        switch (template) {
            case 'draft':
                setSettings({
                    ...settings,
                    width: 512,
                    height: 512,
                    quality: 0,
                    steps: 20,
                    cfg: 7,
                })
                return setPrice(25)
            case "basic":
                setSettings({
                    ...settings,
                    width: 512,
                    height: 512,
                    quality: 0,
                    steps: 40,
                    cfg: 7,
                })
                return setPrice(50)
            case "standard":
                setSettings({
                    ...settings,
                    width: 1024,
                    height: 1024,
                    quality: 0.7,
                    steps: 30,
                    cfg: 7,
                })
                return setPrice(100)
            case "premium":
                setSettings({
                    ...settings,
                    width: 1024,
                    height: 1024,
                    quality: 0.7,
                    steps: 40,
                    cfg: 7,
                })
                return setPrice(200)
            case "supreme":
                setSettings({
                    ...settings,
                    width: 1600,
                    height: 1600,
                    quality: 0.7,
                    steps: 50,
                    cfg: 7,
                })
                return setPrice(300)
            case "custom":
                setSettings({
                    ...settings,
                    width: 512,
                    height: 512,
                    quality: 0,
                    steps: 20,
                    cfg: 7,
                })
                return setPrice(25)
        }
    }
    const calculatePriceInCurrency = (eurPrice) => {
        let currency = rates?.currency
        if (currency === undefined) return <>{eurPrice} EUR</>
        switch (currency) {
            case 'HUF':
                return <>{Math.round(eurPrice * rates?.rates.HUF ?? 400)} HUF</>
            case 'USD':
                return <>{Math.round(eurPrice * rates?.rates.USD ?? 1)} USD </>
            case 'AUD':
                return <>{Math.round(eurPrice * rates?.rates.AUD ?? 1.6)} AUD</>
            case 'GBP':
                return <>{Math.round(eurPrice * rates?.rates.GBP ?? 0.9)} GBP</>
            case 'NZD':
                return <>{Math.round(eurPrice * rates?.rates.NZD ?? 1.7)}NZD</>
            case 'EUR':
                return <>{Math.round(eurPrice)} EUR</>
            case 'ZAR':
                return <>{Math.round(eurPrice * rates?.rates.ZAR ?? 19)}ZAR</>
            case 'CAD':
                return <>{Math.round(eurPrice * rates?.rates.CAD ?? 1.4)} CAD</>
                break;
            default:
                return <>{Math.round(eurPrice * rates?.rates.USD ?? 1)} USD</>
        }
    }
    const calculatePrice = (template) => {
        let amount = Math.round(Math.max(
            25,
            (settings.width || 512) *
            (settings.height || 512) *
            (settings.steps || 20) / 209715.2
        ))


        if (amount > 1000) return 1000
        switch (template) {
            case 'draft':
                return 25
            case "basic":
                return 50
            case "standard":
                return 100
            case "premium":
                return 200
            case "supreme":
                return 300
            case "custom":
                return amount
        }
    }
    const calculatePriceToSend = (eurPrice) => {
        let currency = rates?.currency
        if (currency === undefined) return {price: eurPrice, currency: 'EUR'}
        switch (currency) {
            case 'HUF':
                return {
                    price: eurPrice * rates.rates.HUF,
                    currency: 'HUF'
                }
            case 'USD':
                return {
                    price: eurPrice * rates.rates.USD,
                    currency: 'USD'
                }
            case 'AUD':
                return {
                    price: eurPrice * rates.rates.AUD,
                    currency: 'AUD'
                }
            case 'GBP':
                return {
                    price: eurPrice * rates.rates.GBP,
                    currency: 'GBP'
                }
            case 'NZD':
                return {
                    price: eurPrice * rates.rates.NZD,
                    currency: 'NZD'
                }
            case 'EUR':
                return {
                    price: eurPrice,
                    currency: 'EUR'
                }
            case 'ZAR':
                return {
                    price: eurPrice * rates.rates.ZAR,
                    currency: 'ZAR'
                }
            case 'CAD':
                return {
                    price: eurPrice * rates.rates.CAD,
                    currency: 'CAD'
                }
                break;
            default:
                return {
                    price: eurPrice,
                    currency: 'EUR'
                }
        }
    }

    const sendJobWithPayments = async () => {
        let {last_name, first_name, email, phone, meta, uuid,} = user
        if (settings.prompt < 3) {
            setError("Please add at least 3 characters to your masterpiece")
            return null
        }

        if (!last_name || !first_name || !email || !meta.address || !meta.city || !meta.country || !meta.zip || !meta.state || !phone) {
            setError("Please fill in all profile fields")
            return null
        }
        // if (meta.address.length < 3 || meta.city.length < 3 || meta.country.length < 1 || meta.zip.length < 3) {
        //     setError("Please fill in all profile fields properly")
        //     return null
        // }
        if (!checked) {
            setError("Please accept the terms and conditions")
            return null
        }
        const emptyJob = await EmptyJobCheck(user);
        const p_method = window.location.host.toLowerCase().includes('ink')  ? 'easydeposit2' : 'justtest'
        let settingsForSend = {
            cfg_scale: settings.cfg,
            denoising_strength: settings.quality,
            endable_hr: settings.quality > 0,
            firstphase_height: 0,
            firstphase_width: 0,
            height: settings.height,
            width: settings.width,
            negative_prompt: settings.negative_prompt ?? '',
            prompt: settings.prompt,
            restore_faces: false,
            sampler_index: artist,
            seed: Number(settings.seed),
            steps: settings.steps,
            type: 'txt2img',
        }
        let priceData = calculatePriceToSend(calculatePrice(quality.toLowerCase()))
        let dataToSend = {
            "merchant_id": "dreaminink",
            "save": true,
            job: {
                ...emptyJob, config: settingsForSend,
                status: 'init',
                payment: {
                    "method": p_method,
                    "amount": priceData.price,
                    "currency": priceData.currency,
                    "user": {
                        "user_uuid": uuid,
                        "fname": first_name,
                        "lname": last_name,
                        "address": meta.address,
                        "address2": meta.address2,
                        "city": meta.city,
                        "province": meta.state,
                        "country": meta.country,
                        "post_code": meta.zip,
                        "phone": phone,
                        "email": email
                    }
                }
            }
        }
       

        await axios.post(`https://api.${window.location.host}/pay`, dataToSend, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                console.log(res)
                removeEmptyJobFromLocalStorage()
                // EmptyJobCheck(user)
                window.location.href = res.data.url
            })
            .catch((error) => {
                console.log(error)

            });
    }
    // reset error variable to empty string after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setError('')
        }, 5000);
        return () => clearTimeout(timer);
    }, [error])

    if (!user) {
        return <Navigate to='/signin'/>
    }

    return (
        <div className='page-collection'>
            <PageTitle title='Create'/>
            <section className=" tf-item-detail ">
                <div className="tf-container">
                    <div className="row" style={{display: "flex", justifyContent: "center"}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-inner">
                                <h2 className="title">{Languages[language].quality}</h2>
                                <div className="infor-item-wrap">
                                    <div onClick={() => {
                                        setQuality('draft')
                                        QualitySetter('draft')
                                    }} className={`infor-item-box ${quality === "draft" && "active"}`}>
                                        <div className="category">25€</div>
                                        <h4 className="name">{Languages[language].draft_quality}</h4>
                                    </div>
                                    <div onClick={() => {
                                        setQuality('basic')
                                        QualitySetter('basic')
                                    }} className={`infor-item-box ${quality === "basic" && "active"}`}>
                                        <div className="category">50€</div>
                                        <h4 className="name">{Languages[language].basic_quality}</h4>
                                    </div>
                                    <div onClick={() => {
                                        setQuality('standard')
                                        QualitySetter('standard')
                                    }} className={`infor-item-box ${quality === "standard" && "active"}`}>
                                        <div className="category">100€</div>
                                        <h4 className="name">{Languages[language].standard_quality}</h4>
                                    </div>
                                    <div onClick={() => {
                                        setQuality('premium')
                                        QualitySetter('premium')
                                    }} className={`infor-item-box ${quality === "premium" && "active"}`}>
                                        <div className="category">200€</div>
                                        <h4 className="name">{Languages[language].premium_quality}</h4>
                                    </div>
                                    <div onClick={() => {
                                        setQuality('supreme')
                                        QualitySetter('supreme')
                                    }} className={`infor-item-box ${quality === "supreme" && "active"}`}>
                                        <div className="category">300€</div>
                                        <h4 className="name">{Languages[language].supreme_quality}</h4>
                                    </div>
                                    <div onClick={() => {
                                        setQuality('custom')
                                        QualitySetter('custom')
                                    }} className={`infor-item-box ${quality === "custom" && "active"}`}>
                                        <div className="category">from 25€</div>
                                        <h4 className="name">{Languages[language].custom}</h4>
                                    </div>
                                </div>
                                <h2 className="title">{Languages[language].idea}</h2>
                                <div className='create-inputs'>

                                    <p className="des"> {Languages[language].positive_bottom_text}
                                    </p>

                                    <fieldset style={{marginBottom: '25px'}} className="name">
                                        <textarea
                                            onPaste={(e) => {

                                                let paste = (e.clipboardData || window.clipboardData).getData('text');
                                                setSettings(
                                                    {
                                                        ...settings,
                                                        prompt: paste
                                                    }
                                                )
                                            }}
                                            onChange={(e) => {
                                                setSettings(
                                                    {
                                                        ...settings,
                                                        prompt: e.target.value
                                                    }
                                                )
                                            }} type="text" id="magic" placeholder="Write your idea"
                                            className="tb-my-input" name="name" tabIndex="2" aria-required="true"
                                            required="true"/>
                                    </fieldset>
                                    <fieldset className="name">
                                        <input onChange={(e) => {
                                            setSettings(
                                                {
                                                    ...settings,
                                                    negative_prompt: e.target.value
                                                }
                                            )
                                        }} type="text" id="name" placeholder="Something you want avoid"
                                               className="tb-my-input" tabIndex="2" aria-required="true" required=""/>
                                    </fieldset>
                                </div>
                                <h2 className="title">Artist</h2>
                                <div className="infor-item-wrap ">

                                    <div onClick={() => {
                                        setArtist("Euler a")
                                    }} className={`infor-item-box ${artist === "Euler a" && "active"}`}
                                         style={{padding: '5px'}}
                                    >
                                        <img src={`https://api.${window.location.host}/image/1675257171113`}/>
                                    </div>
                                    <div onClick={() => {
                                        setArtist("Euler")
                                    }} className={`infor-item-box ${artist === "Euler" && "active"}`}
                                         style={{padding: '5px'}}
                                    >
                                        <img src={`https://api.${window.location.host}/image/1675257149929`}/>
                                    </div>
                                    <div onClick={() => {
                                        setArtist("DPM2 a")
                                    }} className={`infor-item-box ${artist === "DPM2 a" && "active"}`}
                                         style={{padding: '5px'}}
                                    >
                                        <img src={`https://api.${window.location.host}/image/1675257209068`}/>
                                    </div>

                                </div>
                                <div className="infor-item-wrap ">
                                    <div onClick={() => {
                                        setArtist("Euler a")
                                    }} className={`infor-item-box ${artist === "Euler a" && "active"}`}>
                                        <div className="category"></div>
                                        <h4 style={{textAlign: 'center'}} className="name">Artist1</h4>
                                    </div>
                                    <div onClick={() => {
                                        setArtist("Euler")
                                    }} className={`infor-item-box ${artist === "Euler" && "active"}`}>
                                        <div className="category"></div>
                                        <h4 style={{textAlign: 'center'}} className="name">Artist2</h4>
                                    </div>
                                    <div onClick={() => {
                                        setArtist("DPM2 a")
                                    }} className={`infor-item-box ${artist === "DPM2 a" && "active"}`}>
                                        <div className="category"></div>
                                        <h4 style={{textAlign: 'center'}} className="name">Artist3</h4>
                                    </div>
                                </div>
                                <h2 className="title">{Languages[language].settings}</h2>
                                <div style={{marginBottom: '20px'}}>
                                    <p className="des"> {Languages[language].seed} : Starting number, the same seed will always give the same
                                        result. (optional)
                                    </p>
                                    <fieldset className="name">
                                        <input
                                            onPaste={(e) => {

                                                let paste = (e.clipboardData || window.clipboardData).getData('text');
                                                setSettings(
                                                    {
                                                        ...settings,
                                                        seed: paste
                                                    }
                                                )
                                            }}
                                            onChange={(e) => {
                                                setSettings(
                                                    {
                                                        ...settings,
                                                        seed: e.target.value
                                                    }
                                                )
                                            }} type="number" id="name" placeholder="seed"
                                            className="tb-my-input" tabIndex="2" aria-required="true" required=""/>
                                    </fieldset>
                                </div>
                                {quality === 'custom' && <div className='custom-inputs'>
                                    <div className='input-wrapper'>
                                        <div id='dimensions' style={{
                                            width: '25px',
                                            height: '25px',
                                            position: 'absolute',
                                            right: '4px',
                                            top: '0px'
                                        }}>
                                            <img src={infoIcon}/>
                                            <Tooltip anchorId="dimensions"
                                                     content={<>You can select from 64px to 2048px <br></br> for height
                                                         or width.</>}/>
                                        </div>
                                        <RangeSlider steps={dimensions}
                                                     indexOfDefault={dimensions.findIndex((i) => i === 512)}
                                                     outputName={Languages[language].width}
                                                     callback={(value) => {
                                                         setSettings({...settings, width: value.value})
                                                     }}/>
                                    </div>
                                    <div className='input-wrapper'>
                                        <div id='dimensions1' style={{
                                            width: '25px',
                                            height: '25px',
                                            position: 'absolute',
                                            right: '4px',
                                            top: '0px'
                                        }}>
                                            <img src={infoIcon}/>
                                            <Tooltip anchorId="dimensions1"
                                                     content={<>You can select from 64px to 2048px <br></br> for height
                                                         or width.</>}/>
                                        </div>
                                        <RangeSlider steps={dimensions}
                                                     indexOfDefault={dimensions.findIndex((i) => i === 512)}
                                                     outputName={Languages[language].height}
                                                     callback={(value) => {
                                                         setSettings({...settings, height: value.value})
                                                     }}/>
                                    </div>
                                    <div className='input-wrapper'>
                                        <div id='qualityID' style={{
                                            width: '25px',
                                            height: '25px',
                                            position: 'absolute',
                                            right: '4px',
                                            top: '0px'
                                        }}>
                                            <img src={infoIcon}/>
                                            <Tooltip anchorId="qualityID" content={<>
                                                The artists work best around 512px <br></br>
                                                and if going past this point it should <br></br>
                                                be done in a single direction or with<br></br>
                                                the assistance of this high-quality <br></br>
                                                setting that will split the work into 512x512 <br></br>
                                                jobs and merge them.<br></br>
                                            </>}/>
                                        </div>
                                        <RangeSlider steps={hugeQualityArray}
                                                     indexOfDefault={hugeQualityArray.findIndex((i) => i === 0)}
                                                     outputName={Languages[language].high_quality}
                                                     callback={(value) => {
                                                         setSettings({...settings, quality: value.value})
                                                     }}/>
                                    </div>
                                    <div className='input-wrapper'>
                                        <div id='StepsID' style={{
                                            width: '25px',
                                            height: '25px',
                                            position: 'absolute',
                                            right: '4px',
                                            top: '0px'
                                        }}>
                                            <img src={infoIcon}/>
                                            <Tooltip anchorId="StepsID" content={<>

                                                Each step allows the artist to improve <br></br>
                                                your image, around 20 steps will get you<br></br>
                                                a basic image, 50 or so would be better<br></br>
                                                for higher quality.<br></br>
                                            </>}/>
                                        </div>
                                        <RangeSlider steps={steps}
                                                     indexOfDefault={steps.findIndex((i) => i === 20)}
                                                     outputName={Languages[language].steps}
                                                     callback={(value) => {
                                                         setSettings({...settings, steps: value.value})
                                                     }}/>
                                    </div>
                                    <div className='input-wrapper'>
                                        <div id='CFGID' style={{
                                            width: '25px',
                                            height: '25px',
                                            position: 'absolute',
                                            right: '4px',
                                            top: '0px'
                                        }}>
                                            <img src={infoIcon}/>
                                            <Tooltip anchorId="CFGID" content={<>
                                                Here you can choose the weight of your<br></br>
                                                input to the piece. A basic setting<br></br>
                                                of around 7 yields results that both<br></br>
                                                represent your ideas and have some<br></br>
                                                freedom of expression. 1 will only<br></br>
                                                contain a hint of your input and <br></br>
                                                30 will most likly over bake the ideas.<br></br>
                                                Suggested: 5-15<br></br>
                                            </>}/>
                                        </div>
                                        <RangeSlider steps={CFG}
                                                     indexOfDefault={CFG.findIndex((i) => i === 7)}
                                                     outputName={Languages[language].artist_freedom_input_label}
                                                     callback={(value) => {
                                                         setSettings({...settings, cfg: value.vale})
                                                     }}/>
                                    </div>
                                </div>}
                                <div className='check-terms'>
                                    <input type="checkbox" onChange={(e) => {
                                        setChecked(e.target.checked)
                                    }} id="terms" name="terms"/>
                                    <label htmlFor="terms">I agree to the <a href="terms">Terms and
                                        Contitions</a> </label>
                                </div>
                                {error.length > 0 &&
                                    <div className="price">
                                        <span className="heading" style={{color: 'red'}}>Error: </span>
                                        <span style={{color: 'red'}}>{error}</span>
                                    </div>}
                                <div className="price">
                                    <span className="heading">PRICE: </span>
                                    <span>{calculatePriceInCurrency(calculatePrice(quality.toLowerCase()))}</span>
                                </div>
                                <div className="group-btn">
                                    <Link onClick={sendJobWithPayments} to="#" className="tf-button opensea"><i
                                        className="icon-fl-bag"></i> Go to pay</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
            <Footer2/>
        </div>
    );
}

export default Create;





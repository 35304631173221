import { Languages } from "../../../Helper/languages";
import { REDIRECT_CONSTANTS } from "../constants";

export const qnas = {
  base: {
    question: "What happened?",

    answers: {
      speed: {
        text: "Speed",
        redirect: "speed_whats_wrong",
      },
      payment: {
        text: "Payment",
        redirect: "payment_whats_wrong",
      },
      quality: {
        text: "Quality",
        redirect: "quality_whats_wrong",
      },
    },
  },

  // speed related
  speed_whats_wrong: {
    question: "What's wrong with the speed?",

    answers: {
      speed_too_slow: {
        text: "Too slow",
        redirect: REDIRECT_CONSTANTS.refund,
      },
      speed_not_loading: {
        text: "Stuck at the end.",
        redirect: REDIRECT_CONSTANTS.refund,
      },
      speed_stuck: {
        text: "Not working.",
        redirect: REDIRECT_CONSTANTS.refund,
      },
    },
  },

  // payment related
  payment_whats_wrong: {
    question: "What's wrong with the payment?",

    answers: {
      payment_still_pending: {
        text: "Already paid, still says pending...",
        redirect: "payment_still_pending"
      },
      payment_charged_twice: {
        text: "I got charged twice.",
        redirect: REDIRECT_CONSTANTS.refund,
      },
    },
  },
  payment_still_pending: {
    question: "Try to generate it again?",

    answers: {
      payment_still_pending_regen: {
        text: "Yes.",
        redirect: REDIRECT_CONSTANTS.close,
        redirect_section_url: "/create"
      },
      payment_still_pending_refund: {
        text: "No, I want refund.",
        redirect: REDIRECT_CONSTANTS.refund,
      },
    },
  },

  // quality related
  quality_whats_wrong: {
    question: "What's wrong with the quality?",

    answers: {
      quality_bad: {
        text: "The image quality is bad.",
        redirect: "quality_bad",
      },
      payment_charged_twice: {
        text: "Image is not generated fully.",
        redirect: REDIRECT_CONSTANTS.refund,
      },
    },
  },
  quality_bad: {
    question: "Try to generate it again?",

    answers: {
      quality_bad: {
        text: "Yes.",
        redirect: REDIRECT_CONSTANTS.close,
        redirect_section_url: "/create"
      },
      payment_charged_twice: {
        text: "No, I want a refund.",
        redirect: REDIRECT_CONSTANTS.refund,
      },
    },
  },



  //base last refund
  refund: {
    question: "Sorry to hear that.",

    answers: {
      refund_refund: {
        text: "I want a refund!",
        redirect: REDIRECT_CONSTANTS.end,
      },
      refund_free_token: {
        text: "I want a free token",
        redirect: REDIRECT_CONSTANTS.end,
      },
    },
  },
};

export const getQNA = (lang) => {
  let paired = {};

  Object.entries(qnas).map((qna, index) => {
    const qnaId = qna[0];
    let qnaContent = qna[1];

    const qnaTranslation = Languages[lang].qna[qnaId];

    if (!paired.hasOwnProperty(qnaId) && qnaTranslation) {
      paired[qnaId] = {
        question: qnaTranslation && qnaTranslation.question,
        answers: {},
      };
    }

    const answerEntries = Object.entries(qnaContent.answers);

    answerEntries.map((answer, index) => {
      const answerId = answer[0];
      let answerContent = answer[1];

      if (!qnaTranslation) return;

      const translationValue = Languages[lang].qna[qnaId].answers[answerId];
      const contentValue = qnaContent.answers[answerId];

      paired[qnaId].answers[answerId] = {
        text: translationValue ? translationValue.text : contentValue.text,
        redirect: contentValue ? contentValue.redirect : false,
        redirect_section_url: contentValue ? contentValue.redirect_section_url : false,
      };
    });
  });

  return paired;
};

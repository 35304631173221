import React from 'react';
import dataAbout from '../assets/fake-data/data-about';
import dataBanner from '../assets/fake-data/data-banner';
import dataCollection from '../assets/fake-data/data-collection';
import dataFaqs from '../assets/fake-data/data-faq';
import About1 from '../components/about/About1';
import Banner01 from '../components/banner/Banner01';
import Collection from '../components/collection/Collection';
import Faqs from '../components/faqs/Faqs';
import Footer2 from "../components/footer/Footer2";


function Home01(props) {

    return (
        <div className="home-1">
            <Banner01 data={dataBanner} />
            {/*<Logo />*/}
            <About1 data={dataAbout} />
            <Collection data={dataCollection} />
            <Faqs data={dataFaqs} />
           <Footer2 />
           
        </div>

    );
}

export default Home01;
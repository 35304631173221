import React, { useState,useEffect } from 'react'
import './RangeSlider.scss'

export default function RangeSlider({ callback, steps, indexOfDefault, outputName }) {

    const [sliderCurrentValue, setSliderCurrentValue] = useState(indexOfDefault)
    const breakpoints = steps
    return (
        <>
            <input id="input"

                   onChange={(e) => {
                       callback({ index: sliderCurrentValue, value: breakpoints[Number(e.target.value)] })
                       setSliderCurrentValue(Number(e.target.value))
                   }}
                   type="range" min="0"
                   defaultValue={sliderCurrentValue}
                   max={breakpoints.length - 1}
                   step="1" />

            <div id="output">{!!outputName && outputName}: {breakpoints[sliderCurrentValue]} </div>

        </>
    )
}
import React, {useContext, useState, useEffect} from 'react';
import { Languages } from '../Helper/languages';
import {Navigate} from "react-router-dom";
import {DataContext} from "../Helper/context";
import PageTitle from '../components/pagetitle/PageTitle';
import Footer from '../components/footer';
import icon1 from '../assets/images/svg/loaction.svg'
import icon2 from '../assets/images/svg/email.svg'
import icon3 from '../assets/images/svg/phone.svg'
import icon4 from '../assets/images/svg/member.svg'
import {updateProfile} from "../Helper/functions";
import Footer2 from "../components/footer/Footer2";
import {country} from "../Helper/country";

function Profile() {
    const {user, setUser, language} = useContext(DataContext);
    const [info, setInfo] = useState();
    const [userObject, setUserObject] = useState({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone: user?.phone,
        meta: {
            address: user?.meta?.address,
            address2: user?.meta?.address2,
            city: user?.meta?.city,
            state: user?.meta?.state,
            zip: user?.meta?.zip,
            country: user?.meta?.country ?? 'US',
        }

    });
    useEffect(() => {
        const timer = setTimeout(() => {
            setInfo(null)
        }, 3000);
        return () => clearTimeout(timer);
    }, [info]);
    if (!user) return <Navigate to="/signin"/>

    const handleChange = (e) => {
        e.preventDefault()
        updateProfile(`https://api.${window.location.host}/profile`, userObject, user).then((res) => {
            setUser(res?.data)
            localStorage.setItem('userCredentials', JSON.stringify(res?.data));
            setInfo("Profile updated successfully")
        }).catch((err) => {
            setInfo("Profile update failed")
        })
    }

    return (
        <div>
            <PageTitle title={Languages[language].profile}/>
            <section className="tf-contact ">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-infor-wrap" style={{justifyContent: 'center'}}>
                                <div className="tf-infor">
                                    <div className="icon">
                                        <img src={icon1} alt="Binabox"/>
                                    </div>
                                    <h3 className="title">{Languages[language].location}</h3>
                                    <input placeholder={Languages[language].address} defaultValue={userObject?.meta?.address} onChange={(e)=>{setUserObject({...userObject,meta: {...userObject.meta , address: e.target.value}})}} />
                                    <input placeholder='Address 2' defaultValue={userObject?.meta?.address2} onChange={(e)=>{setUserObject({...userObject,meta: {...userObject.meta , address2: e.target.value}})}} />
                                    <input placeholder={Languages[language].city} defaultValue={userObject?.meta?.city} onChange={(e)=>{setUserObject({...userObject,meta: {...userObject.meta , city: e.target.value}})}} />
                                    <input placeholder={Languages[language].state} defaultValue={userObject?.meta?.state} onChange={(e)=>{setUserObject({...userObject,meta: {...userObject.meta , state: e.target.value}})}} />
                                    {/*<input placeholder='Country' defaultValue={userObject?.meta?.country} onChange={(e)=>{setUserObject({...userObject,meta: {...userObject.meta , country: e.target.value}})}} />*/}
                                        <select

                                            defaultValue={country[userObject?.meta?.country]  ?? country['US']}
                                            onChange={(e) => {
                                            setUserObject({...userObject, meta : {...userObject.meta, country:Object.entries(country).filter((x) => x[1] === e.target.value)[0][0]}})
                                        }}
                                            className="form-control mr-0">
                                            {Object.values(country).map((x, key) => {
                                                return <option key={key} value={x}>{x}</option>
                                            })}
                                        </select>

                                    <input placeholder='Zip' defaultValue={userObject?.meta?.zip} onChange={(e)=>{setUserObject({...userObject,meta: {...userObject.meta , zip: e.target.value}})}} />
                                </div>
                                <div className="tf-infor">
                                    <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                        <div style={{marginLeft: '25px'}} className="icon">
                                            <img style={{width: '56px', height: '56px'}} src={icon4} alt="Binabox"/>
                                        </div>
                                        <div style={{marginLeft: '25px'}} className="icon">
                                            <img src={icon2} alt="Binabox"/>
                                        </div>
                                        <div style={{marginLeft: '25px'}} className="icon">
                                            <img src={icon3} alt="Binabox"/>
                                        </div>
                                    </div>
                                    <h3 className="title"> {Languages[language].name_email_phone}</h3>
                                    <input placeholder={Languages[language].first_name} defaultValue={userObject?.first_name} onChange={(e)=>{setUserObject({...userObject,first_name: e.target.value})}} />
                                    <input placeholder={Languages[language].last_name} defaultValue={userObject?.last_name} onChange={(e)=>{setUserObject({...userObject,last_name: e.target.value})}} />
                                    <input placeholder={Languages[language].email} defaultValue={userObject?.email} onChange={(e)=>{setUserObject({...userObject,email: e.target.value})}} />
                                    <input placeholder={Languages[language].phone} defaultValue={userObject?.phone} onChange={(e)=>{setUserObject({...userObject,phone: e.target.value})}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginBottom:'20px'}}>
                        {info !== null && <div>{info}</div>}
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <button
                           onClick={(e)=>{handleChange(e)}}
                            className="tf-button submit" type="submit"

                        >{Languages[language].save}
                        </button>
                    </div>

                </div>

            </section>


            <Footer2/>

        </div>
    );
}

export default Profile;
import Page404 from "./404";
import Gallery from "./Gallery";
import Faq01 from "./Faq01";
import Home01 from "./Home01";
import Login from "./Login";
import SignUp from "./SignUp";
import Profile from "./Profile";
import Create from "./Create";
import Thanks from "./Thanks";
import Terms from "./Terms";
import View from "./View";
import Inspiration from "./Inspiration";


const routes = [
    {path: '/', component: <Home01/>},
    {path: '/inspiration', component: <Inspiration/>},
    {path: '/profile', component: <Profile/>},
    {path: '/signin', component: <Login/>},
    {path: '/signup', component: <SignUp/>},
    {path: '/faq-v1', component: <Faq01/>},
    {path: '/gallery', component: <Gallery/>},
    {path: '/view', component: <View/>},
    {path: '/create', component: <Create/>},
    {path: '/thanks', component: <Thanks/>},
    {path: '/terms', component: <Terms/>},
    {path: '/page-404', component: <Page404/>},


]

export default routes;
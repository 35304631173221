import React,{useContext}  from 'react';
import { Languages } from '../../Helper/languages';
import {DataContext} from "../../Helper/context";
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap-accordion';

import './styles.scss'

Faqs.propTypes = {
    data: PropTypes.array,
};

function Faqs() {
    const {language} = useContext(DataContext);

    const data = Languages[language].FAQ

    return (
        <section className="tf-faq tf-section">
                <div className="tf-container">
                   <div className="row">
                        <div className="col-md-12 ">
                            <div className="tf-heading mb60 wow fadeInUp">
                                <h2 className="heading">FAQS</h2>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="flat-accordion" data-aos="fade-up" data-aos-duration="800">
                                {
                                     data.map(idx => (
                                        <Accordion show={idx.show} key={idx.id} title={idx.title} className='flat-toggle h6'>
                                       {idx.content}
                                        </Accordion>
                                    ))
                                }

                            </div>
                        </div>



                   </div>
                </div>
            </section>
    );
}

export default Faqs;
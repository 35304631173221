


const dataFaqs = [
    {
        id: 1,
        title: 'WHAT IS AN ARTIST?',
        text : <>
            <p>Each artist is an image generation system.</p>
            <p>It combines previous ideas including:</p>
            <div style={{marginLeft:'20px',marginTop:'20px'}}>
            <ul>
                <li style={{marginBottom:'15px'}}>● Perception Engines which uses image augmentation and iteratively optimises images against an
                    ensemble of classifiers
                </li>
                <li style={{marginBottom:'15px'}}>● CLIP guided GAN imagery from Ryan Murdoch and Katherine Crowson as well as modifictions such as
                    CLIPDraw from Kevin Frans
                </li>
                <li style={{marginBottom:'15px'}}>● Useful ways of navigating latent space from Sampling Generative Networks</li>
            </ul>
            </div>
        </>,
        show: 'show'
    
    },
    {
        id: 2,
        title: 'WHY DOES IT COST MORE FOR LARGER IMAGES?',
        text : <>
            <p>Every image is generated frame by frame and this consumes considerable resources.</p>
            <div style={{marginLeft:'20px',marginTop:'20px', marginBottom:'20px'}}>
            <ul>
                <li>● Draft takes only a few seconds</li>
                <li>● Supreme can take 30 minutes</li>

            </ul>
            </div>
            <p>During this time we have the latest neural network hardware totally consumed by your artwork.</p>
            <p>We are able to scale by purchasing more hardware and process more in parallel.</p>
        </>,
    },
    {
        id: 3,
        title: 'WHY CANT I HAVE A BIGGER IMAGE?',
        text : <><p>We are working on a solution to make even larger images, right now we are constrained by the amount of memory we can have in a single artist. We hope to add the addition of super-sampling to increase the size considerably.</p></>
    },


   
]

export default dataFaqs;
const dataAbout = [
  {
    id: 1,
    title: {
      en: "SUPPLY THE IDEA",
      fr: "Fournir l'idée",
      az: "Fikri təqdim edin",
      uz: "Fikrni tayyorlash",
    },
    text: {
      en: "You supply the concepts for the art and have access to additional advanced options if you want to go crazy",
      fr: "Vous fournissez les concepts pour l'art et avez accès à des options avancées supplémentaires si vous voulez vous amuser",
      az: "Siz mənim üçün məsləhət verə bilərsiniz və əgər çox qələmək istəyirsinizsə, əlavə seçimlərə daxil ola bilərsiniz",
      uz: "Siz san'at uchun tushunchalarni etkazib berasiz va agar siz aqldan ozishni xohlasangiz, qo'shimcha ilg'or variantlardan foydalanishingiz mumkin",
    },
  },
  {
    id: 2,
    title: {
      en: "Pay for the art",
      fr: "Payer pour l'art",
      az: "San'at üçün ödəniş edin",
      uz: "San'at uchun to'lov",
    },
    text: {
      en: "You will be able to pay for the art using the payment options provided.",
      fr: "Vous pourrez payer pour l'art en utilisant les options de paiement fournies.",
      az: "Siz ödəniş variantları ilə san'at üçün ödəniş edə bilərsiniz.",
      uz: "Siz to'lov variantlari orqali san'at uchun to'lov qilishingiz mumkin.",
    },
  },
  {
    id: 3,
    title: {
      en: "THE ARTIST GETS TO WORK…",
      fr: "L'artiste commence à travailler…",
      az: "San'atçı işə başlayır…",
      uz: "San'atchi ishga kiradi…",
    },
    text: {
      en: "You will get the results of the artist’s work under the gallery page.",
      fr: "Vous obtiendrez les résultats du travail de l'artiste sous la page de la galerie.",
      az: "San'atçının işinin nəticələrini qalereya səhifəsindən alacaqsınız.",
      uz: "San'atchi ishining natijalarini qalereya sahifasidan oling.",
    },
  },
  {
    id: 4,
    title: {
      en: "DOWNLOAD YOUR CREATION",
      fr: "TÉLÉCHARGEZ VOTRE CRÉATION",
      az: "YARADILMISHLARINIZI YUKLEYIN",
      uz: "O'zingizning yaratilishingizni yuklab oling",
    },
    text: {
      en: "You can download and have full usage rights to your artwork, along with all the information needed to recreate or remix your creation.",
      fr: "Vous pouvez télécharger et avoir les droits d'utilisation complets de votre œuvre d'art, ainsi que toutes les informations nécessaires pour recréer ou remixer votre création.",
      az: "Siz san'at eserini yükləyə və tam istifadə hüquqlarına malik ola bilərsiniz, yaratmaq və ya yaratmaq üçün lazımi olan bütün məlumatları.",
      uz: "Siz san'at eseringizni yuklab oling va to'liq foydalanish huquqlariga ega bo'ling, yaratish va yaratish uchun kerakli barcha ma'lumotlarga ega bo'ling.",
    },
  },
];

export default dataAbout;

import React,{useContext} from 'react';
import { DataContext } from '../../Helper/context';
import PropTypes from 'prop-types';
import { Navigation, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Languages } from '../../Helper/languages';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import './styles.scss'

import Button from '../button/Button';
Banner01.propTypes = {
    data : PropTypes.array,
};

function Banner01(props) {
    const {data} = props;
    const {language} = useContext(DataContext);
    
    return (
        <section className="tf-slider">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">

                        <Swiper
                            modules={[Navigation,  Scrollbar, A11y ]}
                            spaceBetween={0}
                            slidesPerView={1}
                            className="slider-home"
                            loop= {false}
                        >
                        {
                            data.slice(0,1).map((idx, key) => (
                              console.log(idx[language]),
                                <SwiperSlide key={key}>
                                    <div className="slider-item">
                                        <div className="tf-slider-item">
                                            <div className="content-inner">
                                                <div className="text-typing">
                                                    <h1 className="heading mb0">{idx[language].with}</h1>
                                                    <div className="animate-contain">
                                                        <div className="animated-text">
                                                            <span>{idx[language].ai}</span>
                                                            <span>{idx[language].machine}</span>
                                                            <span>{idx[language].us}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h1 className="heading">{idx[language].title} </h1>
                                                <p className="sub-heading">{idx[language].desc}</p>
                                                <div className="btn-slider ">
                                                    <Button path='/create' title={Languages[language].create} />

                                                    
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </SwiperSlide>
                                
                            ))
                        }
                    </Swiper>

                        
                    </div>
                    <div className="col-md-12">
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner01;
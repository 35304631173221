import React, { useContext, useMemo, useState } from "react";
import ButtonOne from "../../button/ButtonOne";
import { DataContext } from "../../../Helper/context";
import { Languages } from '../../../Helper/languages';
import { getQNA } from "../helpers/getQnaTranslations";
import { POP_SECTIONS, REDIRECT_CONSTANTS } from "../constants";

const Qna = React.memo(({ setShown, setPopPage, addAnswer }) => {
  const [currentQuestion, setCurrentQuestion] = useState("base");
  const { language } = useContext(DataContext);
  const [answers, setAnswers] = useState([]);
  console.log("answers", answers);

  const qna = useMemo(() => getQNA(language), [language]);

  const handleAnswer = (qa, redirect) => {
     setAnswers((prev)=> [...prev, { question: qa.question, answer: qa.answer }])

    if (redirect == REDIRECT_CONSTANTS.close) {
      setPopPage({
        page: POP_SECTIONS.close_redirect,
        data: {
          redirect_section_url: qa.redirect_section_url,
        },
      });
      return;
    }

    if (!redirect || redirect == REDIRECT_CONSTANTS.end) {
      setPopPage({
        page: POP_SECTIONS.refund,
        data: {
          answers,
        },
      });
      return;
    }

    setCurrentQuestion(redirect);
  };

  return (
    <div className="h-100 d-flex flex-column" style={{width:'100%'}}>
      <div
        style={{
          minHeight: "30%",
        }}
      >
        <h1 className="fs-2"
        style={{
          color: "black"
        }}
        >
          {qna[currentQuestion]
            ? qna[currentQuestion].question
            : Languages[language].pop_something_went_wrong}
        </h1>
      </div>

      {Object.entries(qna[currentQuestion].answers).map((answer, index) => {
        return (
          <ButtonOne
            key={index}
            className="w-100 text-center fs-6 style-1 p-2 my-2"
            _callback={() => {
              handleAnswer(
                {
                  question: qna[currentQuestion].question,
                  answer: answer[1].text,
                  redirect_section_url: answer[1].redirect_section_url,
                },
                answer[1].redirect
              );
            }}
            text={answer[1].text}
            path={false}
          />
        );
      })}

      <ButtonOne
        className="w-100 text-center fs-6 style-2 p-2 mt-auto bg-black"
        text={Languages[language].pop_qna_close_text}
        _callback={() => setShown(false)}
        path={false}
      />
    </div>
  );
});
export default Qna;

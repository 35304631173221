const dataBanner = [
  {
    en: {
      id: 1,
      title: "Create pictures almost instantly",
      desc: "You can supply the concepts for the art",
      with: "With",
      ai: "AI",
      machine: "Machine",
      us: "us",
    },
    fr: {
      id: 1,
      title: "Créer des images presque instantanément",
      desc: "Vous pouvez fournir les concepts pour l'art",
        with: "Avec",
        ai: "IA",
        machine: "Machine",
        us: "nous",
    },
    //azerbajani
    az: {
        id: 1,
        title: "Şəkillər nə qədər tez yaradılır",
        desc: "Siz mənim üçün məsləhət verə bilərsiniz",
        with: "Bilər",
        ai: "AI",
        machine: "Mashina",
        us: "biz",

    },
    //uzbek
    uz: {
        id: 1,
        title: "Rasmlarni tezda yaratish",
        desc: "Siz mənim üçün məsləhət verə bilərsiniz",
        with: "Bilan",
        ai: "AI",
        machine: "Mashina",
        us: "biz",

    }
  },
  // {
  //   en: {
  //     id: 2,
  //     title: "Set your mind free",
  //     desc: "We ",
  //     with: "With",
  //     ai: "AI",
  //     machine: "Machine",
  //     us: "us",

  //   },
  //   fr: {
  //     id: 2,
  //     title: "Faites quelque chose de fantastique en quelques minutes",
  //     desc: "Écrivez votre magie et la machine fera le reste",
  //     with: "Avec",
  //     ai: "IA",
  //     machine: "Machine",
  //     us: "nous",
  //   },
  //   //azerbajani
  //   az: {
  //       id: 2,
  //       title: "Bir neçə dəqiqədə nə qədər çox şey yaradın",
  //       desc: "Sizin məsləhətiniz və makinanın qalan hissəsi",
  //       with: "bilər",
  //       ai: "AI",
  //       machine: "Mashina",
  //       us: "biz",
  //   },
  //   //uzbek
  //   uz: {
  //       id: 2,
  //       title: "Bir necha daqiqada necha narsa yaratish",
  //       desc: "Sizin məsləhətiniz və makinanın qalan hissəsi",
  //       with: "bilan",
  //       ai: "AI",
  //       machine: "Mashina",
  //       us: "biz",
  //   }
  // },
];

export default dataBanner;

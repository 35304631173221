import React from "react";
import { qnas } from "../components/pop-thingy/helpers/getQnaTranslations";

export const Languages = {
    en: {
        //buttons
        high_quality: "High Quality",
        login: 'Login',
        register: 'Register',
        logout: 'Sign out',
        profile: 'Profile',
        gallery: 'Gallery',
        create: 'Create',
        inspiration: 'Inspiration',
        make_art: 'Make Art',
        my_gallery: 'My Gallery',
        generate_picture: 'Generate Picture',
        submit: 'Submit',
        refresh: 'Refresh',
        download: 'Download',
        go_to_customize: 'Go to Customize',
        save: 'Save',
        delete: 'Delete',
        go_to_create: 'Go to Create',
        terms_and_conditions: 'Terms and Conditions',
        privacy_policy: 'Privacy Policy',

        qna: qnas,

        pop_refund_title: "Which image made you make this decision?",
        pop_refund_button: "Accept & Send",
        pop_refund_button_disabled: "Select atleast one!",

        pop_redirect_close: "Alright. Hope you can solve it.",
        pop_redirect_redirecting: "Redirecting...",
        pop_title: "Not feeling satisfied?",
        pop_text: "Let us solve your problem.",
        pop_button_text: "Click here!",
        pop_qna_close_text: "Close",

        //profile
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        password: 'Password',
        confirm_password: 'Confirm Password',
        phone: 'Phone',
        address: 'Address',
        city: 'City',
        state: 'State',
        zip: 'Zip',
        country: 'Country',
        county: 'County',

        //inspiration
        steps: 'Steps',
        seed: 'Seed',
        width: 'Width',
        height: 'Height',
        artist: 'Artist',
        template: 'Template',
        input: 'Input',
        price: 'Price',
        create_amazing_pictures: 'Create Amazing Pictures',

        //footer action div
        footer_action_div_top_text: 'Create Your New Art',
        footer_action_div_bottom_text: 'Generate something amazing',
        //create page
        positive_top_text: "Tell us about your masterpiece",
        positive_bottom_text: <>Enter some text for the artist to create your image, it works best if you think of your ideas as captions for the result you would like.<br></br></>,
        avoid_top_text: 'Is there something you want to avoid',
        avoid_bottom_text: <>You can add things that you don't want to see in your image here. as above it's best to use captions of the items to avoid.</>,
        artist_top_text: 'Your artist',
        artist_bottom_text: <>There are currently 3 artists to choose from. These artists will make different decisions each step of the way to create your magic. you can see some examples above; for beginners we suggest artist 1</>,
        size_top_text: 'What size would you like your works',
        size_bottom_text: 'You can select from 64px to 2048px for height or width.',
        high_top_text: 'Going BIG?',
        high_bottom_text: 'The artists work best around 512px and if going past this point it should be done in a single direction or with the assistance of this high-quality setting that will split the work into 512x512 jobs and merge them.',
        high_input_label: 'High Quality',
        steps_top_text: 'Steps',
        steps_bottom_text: 'Each step allows the artist to improve your image, around 20 steps will get you a basic image, 50 or so would be better for higher quality.',
        steps_input_label: 'Sampling steps',
        artist_freedom_top_text: 'Artist Freedom',
        artist_freedom_bottom_text: <>Here you can choose the weight of your input to the piece. A basic setting of around 7 yields results that both represent your ideas and have some freedom of expression. 1 will only contain a hint of your input and 30 will most likly over bake the ideas.
            <br></br> Suggested: 5-15</>,
        artist_freedom_input_label: 'CFG scale',
        //create page/quality
        starting_number: 'Starting number',
        starting_number_bottom_text: 'Optional',
        draft_quality: 'Draft',
        draft_quality_bottom_text: 'Most cost-effective',
        basic_quality: 'Basic',
        basic_quality_bottom_text: 'More refined',
        standard_quality: 'Standard',
        standard_quality_bottom_text: 'Double the size',
        premium_quality: 'Premium',
        premium_quality_bottom_text: 'More refined',
        supreme_quality: 'Supreme',
        supreme_quality_bottom_text: 'Huge',
        custom: 'Custom',
        custom_bottom_text: 'Go crazy',
        //home page
        home_page_first_slider_top_text: 'Compose your picture with AI',
        home_page_first_slider_bottom_text: 'We are the best way to generate amazing images',
        home_page_second_slider_top_text: 'Create pictures almost instantly',
        home_page_second_slider_middle_text: 'you supply the concepts for the art and have access to additional advanced options if you want to go crazy',
        home_page_second_slider_bottom_text: 'You can download and have full usage rights to your artwork, along with all the information needed to recreate or remix your creation. ',
        idea: 'Idea',
        write: 'Write',
        wait: 'Wait',
        art: 'Art',
        join: 'Join',
        join_top_text: 'Join and create amazing images',
        become: <>Become a Dreamin <br></br> User Now </>,
        register_text: "Register an account with us and get amazing pictures",
        go_to_create_page: 'Go to Create Page',
        go_to_create_page_text: 'Go to create page and create your own images',
        write_your_magic: 'Write your magic',
        write_your_magic_text: 'Write your magic and the machine will do the rest',
        wait_for_the_result: 'Wait for the result',
        wait_for_the_result_text: 'Wait for the results and enjoy your new images',

        FAQ_short_title: 'FAQ',
        FAQ_title: 'Frequently Asked Questions',
        FAQ: [
            {
                id: 1,
                title: 'WHAT IS AN ARTIST?',
                content: <>
                    <p>Each artist is an image generation system.</p>
                    <p>It combines previous ideas including:</p>
                    <ul>
                        <li>● Perception Engines which uses image augmentation and iteratively optimises images against an
                            ensemble of classifiers
                        </li>
                        <br></br>
                        <li>● CLIP guided GAN imagery from Ryan Murdoch and Katherine Crowson as well as modifictions such as
                            CLIPDraw from Kevin Frans
                        </li>
                        <br></br>
                        <li>● Useful ways of navigating latent space from Sampling Generative Networks</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                id: 2,
                title: 'WHY DOES IT COST MORE FOR LARGER IMAGES?',
                content: <>
                    <p>Every image is generated frame by frame and this consumes considerable resources.</p>
                    <ul>
                        <li>● Draft takes only a few seconds</li>
                        <li>● Supreme can take 30 minutes</li>
                        <br></br>
                    </ul>
                    <p>During this time we have the latest neural network hardware totally consumed by your artwork.</p>
                    <p>We are able to scale by purchasing more hardware and process more in parallel.</p>
                </>,
            },
            {
                id: 3,
                title: 'WHY CANT I HAVE A BIGGER IMAGE?',
                content: 'We are working on a solution to make even larger images, right now we are constrained by the amount of memory we can have in a single artist. We hope to add the addition of super-sampling to increase the size considerably.'
            },
        ],

        //unique ones

        home_data1: 'SUPPLY THE IDEA',
        home_data2: 'You supply the concepts for the art and have access to additional advanced options if you want to go crazy',
        home_data3: 'Pay for the art',
        home_data4: 'You will be able to pay for the art using the payment options provided.',
        home_data5: 'THE ARTIST GETS TO WORK…',
        home_data6: 'You will get the results of the artist’s work under the gallery page.',
        home_data7: 'REVEL IN YOUR CREATION',
        home_data8: 'You can download and have full usage rights to your artwork, along with all the information needed to recreate or remix your creation.',
        home_data9: 'Generated Pictures',
        home_data10:
            <>
                You supply the concepts for the art and have access to additional advanced options if you want to go crazy.<br />
                Perception Engines are able to construct representational physical objects and are powered primarily by computational perception.<br />
                VQGAN+CLIP is a neural network architecture that builds upon the revolutionary CLIP architecture published by OpenAI in January 2021.Latent space can be sampled to generate observable data values. <br />
                Learned latent representations often also allow semantic operations with vector space arithmetic.
            </>,
        hot_collection: 'Hot Collection',
        quality: 'Quality',
        settings: 'Settings',
        name_email_phone: 'Name, Email, Phone',
        location: 'Location',
        size: 'Size',
        item_details: 'Item Details',
        home: 'Home',
    },
    //France
    fr: {
        //buttons
        high_quality: 'Haute qualité',
        login: 'Connexion',
        register: 'S\'inscrire',
        logout: 'Déconnexion',
        profile: 'Profil',
        gallery: 'Galerie',
        create: 'Créer',
        inspiration: 'Inspiration',
        make_art: 'Faire de l\'art',
        my_gallery: 'Ma galerie',
        generate_picture: 'Générer une image',
        submit: 'Soumettre',
        refresh: 'Rafraîchir',
        download: 'Télécharger',
        go_to_customize: 'Aller à Personnaliser',
        save: 'Économiser',
        delete: 'Effacer',
        go_to_create: 'Allez dans Créer',
        terms_and_conditions: 'Termes et conditions',
        privacy_policy: 'Politique de confidentialité',

        pop_title: "Vous ne vous sentez pas satisfait(e) ?",
        pop_text: "Laissez-nous résoudre votre problème.",
        pop_button_text: "Cliquez ici !",

        pop_qna_close_text: "Fermer",
        pop_something_went_wrong: "Oups, quelque chose s'est mal passé!",
        qna: {
            base: {
                question: "Qu'est-il arrivé ?",
                answers: {
                    speed: {
                        text: "Vitesse",
                        redirect: "speed_whats_wrong"
                    },
                    payment: {
                        text: "Paiement"
                    },
                    quality: {
                        text: "Qualité"
                    }
                }
            },

            speed_whats_wrong: {
                question: "Qu'est-ce qui ne va pas avec la vitesse ?",
                answers: {
                    speed_too_slow: {
                        text: "Trop lent",
                    },
                    speed_not_loading: {
                        text: "Bloqué à la fin.",
                    },
                    speed_stuck: {
                        text: "Ne fonctionne pas.",
                    },
                },
            },

            payment_whats_wrong: {
                question: "Qu'est-ce qui ne va pas avec le paiement?",
                answers: {
                    payment_still_pending: {
                        text: "Déjà payé, mais ça indique toujours en attente...",
                    },
                    payment_charged_twice: {
                        text: "J'ai été débité deux fois.",
                    },
                }
            },
            payment_still_pending: {
                question: "Essayez de le générer à nouveau?",
                answers: {
                    payment_still_pending_regen: {
                        text: "Oui.",
                    },
                    payment_still_pending_refund: {
                        text: "Non, je veux un remboursement.",
                    },
                },
            },

            quality_whats_wrong: {
                question: "Qu'est-ce qui ne va pas avec la qualité?",
                answers: {
                    quality_bad: {
                        text: "La qualité de l'image est mauvaise.",
                    },
                    payment_charged_twice: {
                        text: "L'image n'est pas entièrement générée.",
                    },
                },
            },
            quality_bad: {
                question: "Essayez de le générer à nouveau?",
                answers: {
                    quality_bad_regen: {
                        text: "Oui.",
                    },
                    quality_bad_refund: {
                        text: "Non, je veux un remboursement.",
                    },
                },
            },

            refund: {
                question: "Désolé d'apprendre que cela n'a pas fonctionné.",
                answers: {
                    refund_refund: {
                        text: "Je souhaite un remboursement.",
                    },
                    refund_free_token: {
                        text: "Je souhaite un jeton gratuit."
                    }
                }
            }
        },

        pop_refund_title: "Quelle image vous a fait prendre cette décision?",
        pop_refund_button: "Accepter et Envoyer",
        pop_refund_button_disabled: "Sélectionnez au moins un!",

        pop_redirect_close: "D'accord. J'espère que vous pourrez le résoudre.",
        pop_redirect_redirecting: "Redirection en cours...",


        //profile
        first_name: 'Prénom',
        last_name: 'Nom de famille',
        email: 'E-mail',
        password: 'Passe',
        confirm_password: 'Confirmez le mot de passe',
        phone: 'Téléphoner',
        address: 'Adresse',
        city: 'Ville',
        state: 'Etat',
        zip: 'Zip ',
        country: 'Pays',
        county: 'Comté',
        //inspiration
        steps: 'Pas',
        seed: 'Planter',
        width: 'Largeur',
        height: 'Hauteur',
        artist: 'Artiste',
        template: 'Modèle',
        input: 'Saisir',
        price: 'Prix',
        create_amazing_pictures: 'Créez des images étonnantes',

        idea: 'Idée',
        write: 'Écrire',
        wait: 'Attendre',
        art: 'Art',
        //footer action div
        footer_action_div_top_text: 'Créez votre nouvel art',
        footer_action_div_bottom_text: 'Générez quelque chose d\'incroyable',
        //create page
        positive_top_text: "Parlez-nous de votre chef-d'œuvre",
        positive_bottom_text: <>Entrez du texte pour que l'artiste crée votre image, cela fonctionne mieux si vous considérez vos idées comme des légendes pour le résultat que vous souhaitez.<br></br></>,
        avoid_top_text: 'Y a-t-il quelque chose que vous voulez éviter',
        avoid_bottom_text: <>Vous pouvez ajouter ici des choses que vous ne voulez pas voir dans votre image. comme ci-dessus, il est préférable d'utiliser les légendes des éléments à éviter.</>,
        artist_top_text: 'Votre artiste',
        artist_bottom_text: <>Il y a actuellement 3 artistes parmi lesquels choisir. Ces artistes prendront des décisions différentes à chaque étape du processus pour créer votre magie. vous pouvez voir quelques exemples ci-dessus; pour les débutants, nous suggérons</>,
        size_top_text: 'Quelle taille souhaitez-vous pour vos oeuvres',
        size_bottom_text: 'Vous pouvez sélectionner de 64px à 2048px pour la hauteur ou la largeur.',
        high_top_text: 'Aller GRAND?',
        high_bottom_text: 'les artistes travaillent mieux autour de 512px et si vous dépassez ce point, cela devrait être fait dans une seule direction ou avec l\'aide de ce paramètre de haute qualité qui divisera le travail en 512x512 travaux et les fusionnera.',
        high_input_label: 'Haute qualité',
        steps_top_text: 'Pas',
        steps_bottom_text: 'chaque étape permet à l\'artiste d\'améliorer votre image, environ 20 étapes vous permettront d\'obtenir une image de base, une cinquantaine serait préférable pour une meilleure qualité.',
        steps_input_label: 'Étapes d\'échantillonnage',
        artist_freedom_top_text: 'Liberté de l\'artiste',
        artist_freedom_bottom_text: <>ici, vous pouvez choisir le poids de votre contribution à la pièce. Un réglage de base d'environ 7 donne des résultats qui représentent à la fois vos idées et une certaine liberté d'expression. 1 ne contiendra qu'un soupçon de votre contribution et 30 contiendra très probablement les idées.
            <br></br> Suggéré : 5-15</>,
        artist_freedom_input_label: 'CFG scale',
        //create page/quality
        starting_number: 'Numéro de départ',
        starting_number_bottom_text: 'Vous pouvez éventuellement spécifier un point de départ pour votre art. Cela permettra des ajustements futurs.',
        draft_quality: 'Qualité brouillon',
        draft_quality_bottom_text: 'Le plus rentable',
        basic_quality: 'Qualité de base',
        basic_quality_bottom_text: 'Plus raffiné',
        standard_quality: 'Qualité standard',
        standard_quality_bottom_text: 'Doubler la taille',
        premium_quality: 'Qualité supérieure',
        premium_quality_bottom_text: 'Plus raffiné',
        supreme_quality: 'Qualité suprême',
        supreme_quality_bottom_text: 'Énorme',
        custom: 'Personnalisé',
        custom_bottom_text: 'Devenir fou',
        //home page
        home_page_first_slider_top_text: 'Créez quelque chose d\'incroyable en quelques minutes',
        home_page_first_slider_bottom_text: 'La machine fait tout le travail',

        home_page_second_slider_top_text: 'Créez des images presque instantanément',
        home_page_second_slider_middle_text: 'vous fournissez les concepts de l\'art et avez accès à des options avancées supplémentaires si vous voulez devenir fou',
        home_page_second_slider_bottom_text: 'Vous pouvez télécharger et disposer de tous les droits d\'utilisation de votre œuvre, ainsi que de toutes les informations nécessaires pour recréer ou remixer votre création. ',

        join: 'Rejoindre',
        join_top_text: 'Rejoignez et créez des images étonnantes',
        become: <>Devenez un utilisateur d'Dreamin <br></br> maintenant</>,
        register_text: "Enregistrez un compte avec nous et obtenez de superbes photos",
        go_to_create_page: 'Aller à Créer une page',
        go_to_create_page_text: 'Accédez à la page de création et créez vos propres images',
        write_your_magic: 'Écrivez votre magie',
        write_your_magic_text: 'Écrivez votre magie et la machine fera le reste',
        wait_for_the_result: 'Attendez le résultat',
        wait_for_the_result_text: 'Attendez les résultats et profitez de vos nouvelles images',

        FAQ_short_title: 'FAQ',
        FAQ_title: 'Questions fréquemment posées',
        FAQ: [
            {
                id: 1,
                title: 'QU\'EST-CE QU\'UN ARTISTE?',
                content: <>
                    <p>Chaque artiste est un système de génération d'images.</p>
                    <p>Il combine des idées précédentes, notamment:</p>
                    <ul>
                        <li>● Perception Engines qui utilise l'augmentation d'image et optimise de manière itérative les images par rapport à un
                            ensemble de classificateurs
                        </li>
                        <br></br>
                        <li>● Imagerie GAN guidée par CLIP de Ryan Murdoch et Katherine Crowson ainsi que des modifications telles que
                            CLIPDraw de Kevin Frans
                        </li>
                        <br></br>
                        <li>● Moyens utiles de naviguer dans l'espace latent à partir des réseaux génératifs d'échantillonnage</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                id: 2,
                title: 'POURQUOI LES IMAGES PLUS GRANDES COÛTENT-ELLES PLUS GRANDES ??',
                content: <>
                    <p>Chaque image est générée image par image et cela consomme des ressources considérables.</p>
                    <ul>
                        <li>● Le brouillon ne prend que quelques secondes</li>
                        <li>● Suprême peut prendre 30 minutes</li>
                        <br></br>
                    </ul>
                    <p>Pendant ce temps, nous avons le dernier matériel de réseau neuronal totalement consommé par vos œuvres d'art.</p>
                    <p>Nous pouvons évoluer en achetant plus de matériel et en traitant davantage en parallèle.</p>
                </>,
            },
            {
                id: 3,
                title: 'POURQUOI JE NE PEUX PAS AVOIR UNE PLUS GRANDE IMAGE?',
                content: 'Nous travaillons sur une solution pour faire des images encore plus grandes, en ce moment nous sommes limités par la quantité de mémoire que nous pouvons avoir chez un seul artiste. Nous espérons ajouter l\'ajout de super-échantillonnage pour augmenter considérablement la taille.'
            },
        ],
        //unique ones

        home_data1: 'FOURNIR L\'IDÉE',
        home_data2: 'Vous fournissez les concepts de l\'art et avez accès à des options avancées supplémentaires si vous voulez devenir fou',
        home_data3: 'Payer pour l\'art',
        home_data4: 'Vous pourrez payer l\'art en utilisant les options de paiement fournies.',
        home_data5: 'L\'ARTISTE SE MET AU TRAVAIL…',
        home_data6: 'Vous obtiendrez les résultats du travail de l\'artiste sous la page de la galerie.',
        home_data7: 'RAVIEZ-VOUS DE VOTRE CRÉATION',
        home_data8: 'Vous pouvez télécharger et disposer de tous les droits d\'utilisation de votre œuvre, ainsi que de toutes les informations nécessaires pour recréer ou remixer votre création.',
        home_data9: 'Images générées',
        home_data10:
            <>
                Vous fournissez les concepts pour l'art et avez accès à des options avancées supplémentaires si vous voulez devenir fou.<br />
                Les moteurs de perception sont capables de construire des objets physiques représentatifs et sont principalement alimentés par la perception computationnelle.<br />
                VQGAN+CLIP est une architecture de réseau neuronal qui s'appuie sur l'architecture CLIP révolutionnaire publiée par OpenAI en janvier 2021. L'espace latent peut être échantillonné pour générer des valeurs de données observables. <br />
                Les représentations latentes apprises permettent souvent aussi des opérations sémantiques avec l'arithmétique de l'espace vectoriel.
            </>,
        hot_collection: 'Collection chaude',
        quality: 'Qualité',
        settings: 'Paramètres',
        name_email_phone: 'Nom, email, téléphone',
        location: 'Emplacement',
        size: 'Taille',
        item_details: 'Détails de l\'article',
        home: 'Accueil',
    },


    //uzbek
    uz: {
        //buttons
        high_quality: "Yuqori sifatli",
        login: 'Kirish',
        register: 'Ro\'yxatdan o\'tish',
        logout: 'Chiqish',
        profile: 'Profil',
        gallery: 'Galereya',
        create: 'Yaratish',
        inspiration: 'Inspiratsiya',
        make_art: 'San\'at yaratish',
        my_gallery: 'Mening galereyam',
        generate_picture: 'Rasm yaratish',
        submit: 'Yuborish',
        refresh: 'Yangilash',
        download: 'Yuklab olish',
        go_to_customize: 'Ko\'rsatish',
        save: 'Saqlash',
        delete: 'O\'chirish',
        go_to_create: 'Yaratish',
        terms_and_conditions: 'Shartlar va shartlar',
        privacy_policy: 'Maxfiylik siyosati',

        pop_title: "Agar o'ziga etarli his qilmagan bo'lsangiz?",
        pop_text: "Muammo biz tomonimizdan yechilsin.",
        pop_button_text: "Bu yerni bosing!",

        pop_qna_close_text: "Yopish",
        pop_something_went_wrong: "Xatolik yuz berdi!",
        qna: {
            base: {
                question: "Nima yuz berdi?",
                answers: {
                    speed: {
                        text: "Tezlik",
                        redirect: "speed_whats_wrong"
                    },
                    payment: {
                        text: "To'lov"
                    },
                    quality: {
                        text: "Sifat"
                    }
                }
            },

            speed_whats_wrong: {
                question: "Tezlikda nima yuz berdi?",
                answers: {
                    speed_too_slow: {
                        text: "Juda sekin",
                    },
                    speed_not_loading: {
                        text: "Tugatish jarayonida to'xtadi.",
                    },
                    speed_stuck: {
                        text: "Ishlamaydi.",
                    },
                },
            },

            payment_whats_wrong: {
                question: "To'lovda nima yuz berdi?",
                answers: {
                    payment_still_pending: {
                        text: "To'lov amalga oshmagan xolatda ko'rsatilmoqda...",
                    },
                    payment_charged_twice: {
                        text: "Meni ikki marta yechib olgan.",
                    },
                }
            },
            payment_still_pending: {
                question: "Qaytadan yaratib ko'ring?",
                answers: {
                    payment_still_pending_regen: {
                        text: "Ha.",
                    },
                    payment_still_pending_refund: {
                        text: "Yo'q, menda qaytarib olishni hohlaysiz.",
                    },
                },
            },

            quality_whats_wrong: {
                question: "Sifatda nima yuz berdi?",
                answers: {
                    quality_bad: {
                        text: "Rasm sifati yomon.",
                    },
                    payment_charged_twice: {
                        text: "Rasm butunlay yaratilmaydi.",
                    },
                },
            },
            quality_bad: {
                question: "Qaytadan yaratib ko'ring?",
                answers: {
                    quality_bad_regen: {
                        text: "Ha.",
                    },
                    quality_bad_refund: {
                        text: "Yo'q, menda qaytarib olishni hohlaysiz.",
                    },
                },
            },

            refund: {
                question: "Bu ishlay olmay qoldi deb eslaysizdan qattiq uzur.",
                answers: {
                    refund_refund: {
                        text: "Menda qaytarib olishni istayman.",
                    },
                    refund_free_token: {
                        text: "Men bepul jeton istayman."
                    }
                }
            }
        },

        pop_refund_title: "Bu qarorni qabul qilishga qanday rasmdan iborat?",
        pop_refund_button: "Qabul qilish va yuborish",
        pop_refund_button_disabled: "Kamida bitta tanlang!",

        pop_redirect_close: "Tushunarli. U umid qilamizki, siz uni hal qila olasiz.",
        pop_redirect_redirecting: "Yo'naltirish amalga oshirilyapti...",


        //profile
        first_name: 'Ism',
        last_name: 'Familiya',
        email: 'Elektron pochta',
        password: 'parol',
        confirm_password: 'Parolni tasdiqlang',
        phone: 'telefon',
        address: 'Manzil',
        city: 'shahar',
        state: 'davlat',
        zip: 'Zip',
        country: 'mamlakat',
        county: 'County',

        //inspiration
        steps: 'Qadamlar',
        seed: 'Urug\'',
        width: 'Kengligi',
        height: 'Balandligi',
        artist: 'Rassom',
        template: 'Shablon',
        input: 'Kiritish',
        price: 'Narxi',
        create_amazing_pictures: 'Ajoyib rasmlar yaratish',

        //footer action div
        footer_action_div_top_text: 'O\'zingizning yangi san\'atingizni yarating',
        footer_action_div_bottom_text: 'Ajoyib narsani yarating',
        //create page
        positive_top_text: "O'z asaringiz haqida bizga xabar bering",
        positive_bottom_text: <>Tasviringizni yaratishi uchun rassomga matn kiriting, agar siz o'z g'oyalaringizni o'zingiz xohlagan natija uchun sarlavha sifatida o'ylasangiz yaxshi bo'ladi.<br></br></>,
        avoid_top_text: 'Siz qochmoqchi bo\'lgan narsa bormi',
        avoid_bottom_text: <>Tasviringizda ko'rishni istamagan narsalarni bu yerga qo'shishingiz mumkin. Yuqoridagi kabi, oldini olish uchun elementlarning taglavhalaridan foydalanish yaxshidir.</>,
        artist_top_text: 'Rassomni tanlang',
        artist_bottom_text: <>Hozirda tanlash uchun 3 ta rassom bor. Bu rassomlar sizning sehringizni yaratish uchun har bir qadamda turli xil qarorlar qabul qiladilar. yuqorida ba'zi misollarni ko'rishingiz mumkin; Yangi boshlanuvchilar uchun rassom 1ni taklif qilamiz</>,
        size_top_text: 'Ishlaringiz qanday o\'lchamda bo\'lishini xohlaysiz',
        size_bottom_text: 'Siz balandlik yoki kenglik uchun 64px dan 2048px gacha tanlashingiz mumkin.',
        high_top_text: 'KATTA ketyapsizmi?',
        high_bottom_text: 'Rassomlar 512px atrofida eng yaxshi ishlaydi va agar bu nuqtadan o\'tib ketsangiz, u bitta yo\'nalishda yoki ishni 512x512 ishlarga bo\'ladigan va ularni birlashtiradigan ushbu yuqori sifatli sozlamalar yordamida amalga oshirilishi kerak.',
        high_input_label: 'Yuqori sifatli',
        steps_top_text: 'Qadamlar',
        steps_bottom_text: 'Har bir qadam rassomga rasmingizni yaxshilashga imkon beradi, taxminan 20 qadam sizga asosiy tasvirni beradi, yuqori sifat uchun 50 yoki undan ko\'proq yaxshi bo\'ladi.',
        steps_input_label: 'Namuna olish bosqichlari',
        artist_freedom_top_text: 'Rassomning ozodligi',
        artist_freedom_bottom_text: <>Bu erda siz bir qismga kirishingizning og'irligini tanlashingiz mumkin. 7 ga yaqin asosiy sharoit hosil qiladi, ikkalasi ham sizning g'oyalaringizni ifodalaydi va fikr erkinligi bor. 1 faqat sizning kiritishingiz mumkin va 30 ta g'oyalarni oshirib yuboradi.
            <br></br> Taklif qildi: 5-15</>,
        artist_freedom_input_label: 'CFG shkalasi',
        //create page/quality
        starting_number: 'Boshlang\'ich raqami',
        starting_number_bottom_text: 'Ixtiyoriy',
        draft_quality: 'Qoralama',
        draft_quality_bottom_text: 'Eng tejamkor',
        basic_quality: 'Asosiy',
        basic_quality_bottom_text: 'Ko\'proq ma\'lumotga ega',
        standard_quality: 'Standart',
        standard_quality_bottom_text: 'O\'lchamini ikki baravar oshiring',
        premium_quality: 'Mukofot',
        premium_quality_bottom_text: 'Ko\'proq ma\'lumotga ega',
        supreme_quality: 'Oliy',
        supreme_quality_bottom_text: 'Ulkan',
        custom: 'Odat',
        custom_bottom_text: 'Aqldan ozmoq',
        //home page
        home_page_first_slider_top_text: 'Rasmingizni ai bilan tuzing',
        home_page_first_slider_bottom_text: 'Biz ajoyib tasvirlarni yaratishning eng yaxshi usulimiz',
        home_page_second_slider_top_text: 'Darhol rasm yarating',
        home_page_second_slider_middle_text: 'Siz san\'at uchun tushunchalarni etkazib berasiz va agar siz aqldan ozishni xohlasangiz, qo\'shimcha ilg\'or variantlardan foydalanishingiz mumkin',
        home_page_second_slider_bottom_text: 'Siz o\'zingizning yaratilishingiz yoki yaratilishingizni qayta tiklash yoki remiksni qayta tiklash yoki remiks bilan to\'liq foydalanishingiz mumkin.',
        idea: 'Fikr',
        write: 'Yozmoq',
        wait: 'Kutmoq',
        art: 'San\'at',
        join: 'Qo\'shilmoq',
        join_top_text: 'Qo\'shilish va ajoyib tasvirlarni yaratish',
        become: <>Orzular orzusi - <br></br> foydalanuvchi </>,
        register_text: "Biz bilan hisobni ro'yxatdan o'tkazing va ajoyib rasmlarga ega bo'ling",
        go_to_create_page: 'Sahifani yaratish uchun o\'ting',
        go_to_create_page_text: 'Sahifani yaratish va o\'z rasmlaringizni yaratish uchun o\'ting',
        write_your_magic: 'Sehringizni yozing',
        write_your_magic_text: 'Sehringizni yozing va mashina qolganlarni qiladi',
        wait_for_the_result: 'Natija kuting',
        wait_for_the_result_text: 'Natijalarni kuting va yangi rasmlaringizdan zavqlaning',

        FAQ_short_title: 'TSS',
        FAQ_title: 'tez-tez so\'raladigan savollar',
        FAQ: [
            {
                id: 1,
                title: 'Rassom nima?',
                content: <>
                    <p>Har bir rassom - bu rasmni avlod tizimi.</p>
                    <p>Bu oldingi g'oyalar, jumladan:</p>
                    <ul>
                        <li>● Rasmni yutib yuboradigan va iTertativ ravishda rasmlarni eng optimallashtirish
                            tasniflovchilarning ansambli
                        </li>
                        <br></br>
                        <li>● Rayan Murdok va Ketrin Crinsonning Gan tasvirlarini, shuningdek, masalani
                            Kevin Frens-dan ClipDraw
                        </li>
                        <br></br>
                        <li>● Yashirin joylashuvning namunalarini ishlab chiqarish tarmoqlarini tanlab olishning foydali usullari</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                id: 2,
                title: 'Nima uchun kattaroq tasvirlar uchun ko\'proq xarajat qiladi?',
                content: <>
                    <p>Har bir rasm ramka bo'yicha ramka yaratadi va bu katta resurslarni iste'mol qiladi.</p>
                    <ul>
                        <li>● Qoralama bir necha soniya davom etadi</li>
                        <li>● Oliy martaba 30 daqiqa davom etishi mumkin</li>
                        <br></br>
                    </ul>
                    <p>Bu vaqt ichida bizda sizning san'atingiz tomonidan umumiy iste'mol qilinadigan so'nggi Neron tarmoqlari mavjud.</p>
                    <p>Biz parallel ravishda ko'proq apparatni sotib olish va qayta ishlash orqali yanada kengaymoqdamiz.</p>
                </>,
            },
            {
                id: 3,
                title: 'Nega menda katta rasm bor?',
                content: 'Biz yanada kattaroq tasvirlarni yaratish uchun echim ustida ishlamoqdamiz, shu bilan biz yakka tartibdagi xotira miqdori bo\'yicha biz yakka tartibda. Umid qilamizki, katta hajmdagi hajmni sezilarli darajada oshirish uchun qo\'shamiz.'
            },
        ],

        //unique ones

        home_data1: 'Fikrni ta\'minlang',
        home_data2: 'Siz san\'at uchun tushunchalarni etkazib berasiz va agar siz aqldan ozishni xohlasangiz, qo\'shimcha ilg\'or variantlardan foydalanishingiz mumkin',
        home_data3: 'San\'at uchun to\'lash',
        home_data4: 'Siz taqdim etilgan to\'lov variantlaridan foydalangan holda san\'at uchun pul to\'lashingiz mumkin.',
        home_data5: 'Rassom ishga kirishadi ...',
        home_data6: 'Galereya sahifasida rassomning ishi natijalarini olasiz.',
        home_data7: 'O\'zingizning yaratilishingizda',
        home_data8: 'Siz o\'zingizning yaratilishingiz yoki yaratilishingizni qayta tiklash yoki remiksni qayta tiklash yoki remiks bilan to\'liq foydalanishingiz mumkin.',
        home_data9: 'Ishlab chiqarilgan rasmlar',
        home_data10:
            <>
                Siz san'at uchun tushunchalarni etkazib berasiz va agar siz aqldan ozishni xohlasangiz qo'shimcha ilg'or variantlardan foydalanish imkoniyatiga egasiz. <br />
                Ichki motorlar vakillik jismoniy ob'ektlarni qurish imkoniyatiga ega va asosan hisoblash idrokiga ega. <br />
                VQARGAN + Klip - bu 2021 yil yanvarda nashr etilgan inqilobiy klip arxitekturasiga asoslangan neyron tarmoq arxitekturasi, Opqiliq klipektiv klip arxitekturasini yaratadi. <br />
                Mashg'ulotni o'rgangan yashirin vakolatxonalar, shuningdek, semantik operatsiyalarni vektorli bo'sh joylar bilan ta'minlashga imkon beradi.
            </>,
        hot_collection: 'Issiq to\'plam',
        quality: 'Sifat',
        settings: 'Sozlash',
        name_email_phone: 'Ism, elektron pochta, telefon',
        location: 'Manzil',
        size: 'O\'lcham',
        item_details: 'Ma\'lumotlar tafsilotlari',
        home: 'Uy',
    },

}


import React, { useEffect } from "react";
import PopClose from "../components/PopClose";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../../Helper/context";
import { Languages } from '../../../Helper/languages';

const Close = ({ popPage, setShown }) => {
    const { language } = useContext(DataContext)
    const navigate = useNavigate();

    useEffect(() => {
        let timer = setTimeout(() => {
            navigate(popPage.data.redirect_section_url)
            setShown(false)
        }, 2000);
  
        return () => {
            clearTimeout(timer)
        }
    }, [])
    

  return (
    <>
      <PopClose setShown={setShown} />
      <h5 className="mb-2">{Languages[language].pop_redirect_close}</h5>
      <p className="mb-4 text-center">{Languages[language].pop_redirect_redirecting}</p>
    </>
  );
};

export default Close;
